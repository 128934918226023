<template>
    <div class=" ">
      <div class="row">
        <div class="col-9  fs-4  page-heading-parter col-sm-9 col-lg-10">
          <p class="mypracticeMobile" style="margin-left: 20px;">Orders No: {{ 10000 + Number(itemId) }}</p>
        </div>
        <div class="col-2 col-sm-3 col-lg-2">
          <div class="my-3 text-center">
            <button class="btn btn-blue-color text-white" style="border-radius: 10px;" @click="GoSetting()">Back</button>
          </div>
        </div>
      </div>
      <div class="hr-border"></div>
    </div>
  
  
    <div class="nav-tabs-container">
 
  
      <!-- Tab Content -->
      <div class="tab-content">
    
      
        <div :class="{ 'tab-pane': true, 'active': currentTab === 'tab1' }" id="tab1">
          <!-- Content for Tab 2 -->
          <div class="row my-2">
            <div class="col-12 col-md-6 col-lg-4 ">
              <label>Delivery date</label>
              <div class="col-lg-4 col-12 my-1">
                    <input type="text" disabled 
                      class="form-control" v-model="deliveryDate" />
                  </div>
            </div>
          </div>
        </div>
       


        
  
      </div>
    </div>
  
  

  
  </template>
  <script>
  import axios from "axios";
  export default {
 
  
    props: ["docData","role"],

    data() {
      return {
        selectedMeasurement: {
        key: '',
        text: '',
        image: ''
      },
        planned_delivery_date:"",
        modelAcceptedDate:"",
        orderedDate:"",
        modelConfirmDate:"",
        orderAcceptedDate: "",
        orderShippedDate: "",
        itemId: null,
        newClinic: false,
        newClinician: false,
        orderAccepted: true, 
        orderShipped:false,
        hideSaveCancel:true,
        email: '',
        firstName: '',
        mobile: '',
        lastName: '',
        isActive: false,
        coData: {},
        city: "",
        currentTab: 'tab1',
        clinicConsult: [],
        clinicianData: [],
        leftCanvas:[],
        rightCanvas:[],
        hcpsDoctorsId: '',
        acceptedOrder: [],
        filteredConfirmedOrder: [],
        filteredUploadRecords: [],
        clinic_name: "",
        clinic_id: "",
        current_address: "",
        option_address: "",
        clinicName: "",
        coPincode: "",
        editbutton: false,
        hideUpdateShipButton:true,
        currentlyEditedClinic: {},
        currentlyEditedPincodes: {},
        websiteValidationForm: false,
        payloadArray: [],
        nameRegex: /^[A-Za-z.\s]+$/,
        pincodeValidation: /^[1-9](\s*\d){5}$/,
        mobileValidation: /^[6-9]\d{9}$/,
        numberRegex: /^[1-9][0-9]*$/,
        clinicConsultationData: {},
        clinicUpdate: null,

        clinicDetails: {
          name: '',
          clinician: '',
          clinicianPhone: '',
          current_address: '',
          city: '',
          pincode: '',
          mobile: ''
        },
        patientDetails: {
          name: '',
          age: '',
          gender: '',
          height: '',
          weight: ''
        },
  
        otherDatas: {
          size: '',
          model: '',
          recommandations: '',
          caseSheet: ''
        },
        deliveryMode: '', // Holds the selected delivery mode
        handDelivery: {
          name: '',
          phone: ''
        },
        courierDetails: {
          courierName: '',
          consignmentNumber: ''
        },
        showShipping: false,
        canavasImage:"",
        deliveryDate: '', // Bind this to the date input
        shippedDate:""          
      };
    },
    computed: {
  
      areFieldsClinicEmpty() {
        return (
          !this.nameRegex.test(this.clinic_name) ||
          !this.nameRegex.test(this.city) ||
          !this.current_address ||
          !this.pincodeValidation.test(this.coPincode)
        );
      },

     
    },
    methods: {

      showModal(key) {
      this.selectedMeasurement.key = key;
      this.selectedMeasurement.text = this.footMeasurement[key].text;
      this.selectedMeasurement.image = this.footMeasurement[key].image;
    },
      handleLeftImageUpload(imageData) {
        this.uploadedLeftImage = imageData;
      },
  
      toPascalCase(str) {
    return str.replace(/\w+/g, (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  },
     
      async getacceptedOrder() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
        await axios.get(`${BASE_API_URL}/footwear-order`)
          .then((response) => {
            this.acceptedOrder = response.data.filter((orders) => {
              return (
                orders.order_status === "Accepted" ||  orders.order_status === "Shipped" || orders.order_status === "Delivered"
              )
            });
  
          })
        this.filteredConfirmedOrder = this.acceptedOrder.filter((orders) => {
          return (
            orders.id == this.itemId
          )
        })

        const confirmedOrder = this.filteredConfirmedOrder[0]; 
        if (confirmedOrder.order_status === "Accepted" || confirmedOrder.order_status === "Shipped" || confirmedOrder.order_status === "Delivered") {
    this.orderAccepted = false;
    
}
if((confirmedOrder.order_status === "Shipped") ||   (confirmedOrder.order_status === "Delivered") ) {

  this.deliveryDate=confirmedOrder.deliveryDate                                       
}

     if (confirmedOrder.order_status === "Shipped") {
    this.hideUpdateShipButton = false;
    this.showShipping = true;
    this.orderShipped = true;

    this.hideSaveCancel=false

    this.shippedDate = confirmedOrder.shippedDate;
    this.deliveryMode = confirmedOrder.deliveryMode;

    const { handDelivery, courierDetails } = confirmedOrder;

    if (handDelivery?.name || handDelivery?.phone) {
        this.handDelivery = { ...handDelivery };
    } else if (courierDetails?.consignmentNumber || courierDetails?.courierName) {
        this.courierDetails = { ...courierDetails };
    }
}
  this.planned_delivery_date =confirmedOrder.planned_delivery_date
  this.orderedDate = new Date(confirmedOrder.createdAt).toISOString().split('T')[0];
  this.orderAcceptedDate =new Date(confirmedOrder.updateAt).toISOString().split('T')[0];
  this.orderShippedDate =confirmedOrder.shippedDate
// 
  
        
        const colourId = Number(confirmedOrder.selected_colour_model);
  
      const colourResponse = await axios.get(`${BASE_API_URL}/foot-secure/inventory/colour`);
      this.colourData = colourResponse.data?.filter(item => item.id === colourId);
  
      if (this.colourData.length > 0) {
        this.colour_name = this.toPascalCase(this.colourData[0].colour_name)  
      } else {
        console.warn("No colour data found for the selected color model.");
      }
  
  
        this.canavasImage=this.filteredConfirmedOrder[0].uploadedDrawImage
  
  
        if (this.filteredConfirmedOrder.length > 0 && this.filteredConfirmedOrder[0].foot_sketch_images) {
          this.foot_sketch_images = {
            leftFootSketch: this.filteredConfirmedOrder[0].foot_sketch_images.leftFootSketch || '',
            rightFootSketch: this.filteredConfirmedOrder[0].foot_sketch_images.rightFootSketch || ''
          };
  
        }
         if (this.filteredConfirmedOrder.length > 0 && this.filteredConfirmedOrder[0].foot_photographs) {
          this.foot_photographs = {
            anteriorPhoto: this.filteredConfirmedOrder[0].foot_photographs.anteriorPhoto || '',
            dorsalPhoto: this.filteredConfirmedOrder[0].foot_photographs.dorsalPhoto || '',
            leftLateralPhoto: this.filteredConfirmedOrder[0].foot_photographs.leftLateralPhoto || '',
            leftMedialPhoto: this.filteredConfirmedOrder[0].foot_photographs.leftMedialPhoto || '',
            plantarPhoto: this.filteredConfirmedOrder[0].foot_photographs.plantarPhoto || '',
            posteriorPhoto: this.filteredConfirmedOrder[0].foot_photographs.posteriorPhoto || '',
            rightLateralPhoto: this.filteredConfirmedOrder[0].foot_photographs.rightLateralPhoto || '',
            rightMedialPhoto: this.filteredConfirmedOrder[0].foot_photographs.rightMedialPhoto || ''
          }
        }
  
         if (this.filteredConfirmedOrder.length > 0 && this.filteredConfirmedOrder[0].gait_videos) {
          this.gait_videos = {
            anteriorVideoFile: this.filteredConfirmedOrder[0].gait_videos.anteriorVideoFile || '',
            bilateralVideoFile: this.filteredConfirmedOrder[0].gait_videos.bilateralVideoFile || '',
            posteriorVideoFile: this.filteredConfirmedOrder[0].gait_videos.posteriorVideoFile || '',
  
          }
        }
        if (this.filteredConfirmedOrder.length > 0 && this.filteredConfirmedOrder[0].upload_files) {
          this.upload_files = {
            ppsPdfFile: this.filteredConfirmedOrder[0].upload_files.ppsPdfFile || '',
            ppsVideoFile: this.filteredConfirmedOrder[0].upload_files.ppsVideoFile || '',
  
          }
        }
  
  
  
        else {
          console.warn("Foot sketch images are not available for this order.");
        }
  
  
        try {
          const response = await axios.get(`${BASE_API_URL}/upload-records`);
  
          if (response?.data) {
            this.filteredUploadRecords = response.data.filter((orders) => {
              return orders.id == this.filteredConfirmedOrder[0].upload_records_data
            })
            const footwearData = this.filteredConfirmedOrder[0]
            this.leftCanvas =JSON.parse(footwearData.uploadedLeftImage)
            this.rightCanvas =JSON.parse(footwearData.uploadedRightImage)
            this.footArch = {
          left: this.toPascalCase(footwearData.foot_arch.left),
          right: this.toPascalCase(footwearData.foot_arch.right)
        };
  
            this.JointMobility = {
              left: this.toPascalCase(footwearData.Joint_mobility.left),
              right: this.toPascalCase(footwearData.Joint_mobility.right)
            }
            this.areasToOffload = footwearData.Areas_to_offload;
          this.footMeasurement =footwearData.footMeasurement

            this.Offloading_methods = footwearData.Offloading_methods;
            this.otherDatas = {
              size: footwearData.size,
              model: footwearData.confirmed_model,
              recommandations: footwearData.Offloading_Recommendations,
              caseSheet: this.filteredUploadRecords[0].caseSheet.split(', ')
            }
  
          } else {
            console.warn("No data received from the API."); // Log if no data is returned
          }
        } catch (error) {
          console.error("Error fetching upload records:", error); // Log any errors
        }
  
  
        try {
          const response = await axios.get(`${BASE_API_URL}/clinic-consultation`);
  
          if (response?.data) {
            this.filteredClincsByID = response.data.filter((orders) => {
              return orders.id == this.filteredUploadRecords[0].doctor_id.clinic_hcp_id
            })
            const clinicData = this.filteredClincsByID[0];
            this.clinicDetails = {
              name: clinicData.clinic_name || '',
              clinician: this.filteredUploadRecords[0].doctor_id.firstName || '', 
              clinicianPhone: this.filteredUploadRecords[0].doctor_id.mobile || '', 
              current_address: clinicData.current_address || '',
              city: clinicData.city || '',
              pincode: clinicData.pincode || '',
              mobile: clinicData.mobile || ''
            };
          } else {
            console.warn("No data received from the API."); // Log if no data is returned
          }
        } catch (error) {
          console.error("Error fetching upload records:", error); // Log any errors
        }
  
  
        try {
          const response = await axios.get(`${BASE_API_URL}/public-user`);
  
          if (response?.data) {
            this.filteredPatientByID = response.data.filter((patient) => {
              return patient.id == this.filteredConfirmedOrder[0].patient_name
            })
  
            const patientData = this.filteredPatientByID[0];
            this.patientDetails = {
              name: patientData.firstName || '',
              age: patientData.age || '',
              gender: patientData.gender || '',
              height: this.filteredConfirmedOrder[0].height || '',
              weight: this.filteredConfirmedOrder[0].weight || ''
            };
  
          } else {
            console.warn("No data received from the API."); // Log if no data is returned
          }
        } catch (error) {
          console.error("Error fetching upload records:", error); // Log any errors
        }
  
  
  
        // .catch((error) => {
        //   console.error("Error fetching Confirmed Orders:", error);
        // });
      },
  
      showShippingInfo() {
        this.hideUpdateShipButton =false
        this.showShipping = true
      },
      GoBack(){
        this.showShipping = false
  
  
      },

    
  
      ShipOrder() {
        const order = this.filteredConfirmedOrder[0];
        const orderID = order.id;
  
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
  
        // Prepare the payload object based on the delivery mode
        let payloads = {
          shippedDate: this.shippedDate,  // Assuming you've bound `v-model="deliveryDate"` to the date input
          deliveryMode: this.deliveryMode,
          order_status: "Shipped",
        };
  
        // Include additional fields based on the selected delivery mode
        if (this.deliveryMode === 'Handdelivery') {
          payloads.handDelivery = {
            name: this.handDelivery.name,
            phone: this.handDelivery.phone,
          };
        } else if (this.deliveryMode === 'Courier') {
          payloads.courierDetails = {
            courierName: this.courierDetails.courierName,
            consignmentNumber: this.courierDetails.consignmentNumber,
          };
        }
  
        const query = this.$route.query;
  
        axios
          .put(`${BASE_API_URL}/footwear-order/${orderID}/update`, payloads, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response?.data) {

            this.$swal("Shipped!", "The order has been shipped!", "success");
            window.scrollTo(0, 0);
            this.$router.push({ path: `/doctor/manage/orders`, query });
              this.getUserHistory();
            }
          })
          .catch((error) => {
            console.error("Error from server:", error);
          });
      },
  
  
      AcceptOrder() {
        const order = this.filteredConfirmedOrder[0]
        const orderID = order.id
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
        let payloads = {}
  
        payloads = {
          Offloading_Recommendations: this.otherDatas.recommandations,
          order_status: "Accepted",
          planned_delivery_date:this.planned_delivery_date
        };
  
  
        axios
          .put(`${BASE_API_URL}/footwear-order/${orderID}/update`, payloads, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            if (response?.data) {
  
              this.$swal("Accepted!", "The order has been accepted!", "success");
              
              // this.getUserHistory();
            }
          })
          .catch((error) => {
            console.error("Error from server:", error);
          });
      },
  
      cancel(){
        window.scrollTo(0, 0);
        const route = this.role === "doctor" ? "/doctor/manage/orders" : "/user/manage/orders";
        this.$router.push(route);
  
      },
  
  
  
      GoSetting() {
        window.scrollTo(0, 0);
        const route = this.role === "doctor" ? "/doctor/manage/orders" : "/user/manage/orders";
        this.$router.push(route);
      },
  
    
      getClinicConsultation() {
        const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
        axios
          .get(`${BASE_API_URL}/clinic-consultation`)
          .then((response) => {
            const filteredValue = response.data.filter((consult) => {
              return (
                this.coData?.customer?.id === consult?.admin_customer_id?.id
              )
            })
            this.clinicConsult = filteredValue;
            const createdData = this.clinicConsult.filter(item => {
              return item.clinician_others_id?.id === this.hcpsDoctorsId && item.role == 'clinician-others'
            });
            this.clinicConsultationData = createdData.length > 0 ? createdData[0] : null;
            this.clinic_name = this.clinicConsultationData.clinic_name,
              this.city = this.clinicConsultationData.city,
              this.coPincode = this.clinicConsultationData.pincode,
              this.current_address = this.clinicConsultationData.current_address
          })
      },
      async getClinicianOthersData() {
  const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;

  const endpoint = this.role === "doctor" 
    ? `/hcps/${this.hcpsDoctorsId}/one` 
    : `/public-user/${this.hcpsDoctorsId}/one`;

  try {
    const response = await axios.get(`${BASE_API_URL}${endpoint}`);
    this.coData = response.data
    this.coPincode = this.coData.pincode

  } catch (error) {
    console.error("Error fetching clinician data:", error);
  }
},
    },
    created: async function () {
      this.itemId = this.$route.params.id;
      let hcpsDoctorID = localStorage.getItem('id');
  let userID = localStorage.getItem('publicSignUpData');
   this.hcpsDoctorsId = this.role === "doctor" ? JSON.parse(hcpsDoctorID):JSON.parse(userID)
  
      // Call async methods in parallel using Promise.all
      await Promise.all([
        this.getClinicianOthersData(),
        this.getClinicConsultation(),
        this.getacceptedOrder()
      ]);
    }
  }
  </script>
  <style>
  .green-background {
    background-color: green;
    color: white;
    /* Optional: Change text color to white for better readability */
  }
  
  .Setting{
 color: #00979e !important
}
  .offload-table {
    width: 100%;

    border-collapse: collapse;
  }
  
  .offload-table th {
    padding: 10px;
    text-align: center;
    border: 1px solid #ccc;
  }
  

  .offload-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ccc;
}
  .foot-column {
    width: 150px;
    /* Adjust the width as needed */
  }
  
  .nav-tabs-container {
    margin-top: 20px;
  }
  
  .nav-tabs .nav-link {
    cursor: pointer;
  }
  
  .tab-content {
    padding: 10px;
    border: 1px solid #ddd;
    border-top: none;
  }
  
  .clinicSubmitBtn {
    display: flex;
    justify-content: center;
  }
  
  fieldset.curve-box {
    border: 2px solid #00979e;
    border-radius: 20px;
    padding: 15px;
    
  }
  .accordion-body {
  padding: 5px;
}
  legend.subHeadingText {
    padding: 4px 10px;
    margin-bottom: 0px;
    display: block;
    float: none;
    width: auto;
    font-size: 20px;
  }
  
  .allfieldcontent {
    color: red;
    margin-top: 15px
  }
  
  .star {
    color: red;
    font-size: 20px;
  }
  
  .hideOpacity {
    opacity: 0;
  }
  
  .yes-button,
  .no-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .yes-button {
    background-color: #38761D;
    color: #fff;
  }
  
  .no-button {
    background-color: #FF9900;
    color: #fff;
  }
  
  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2) !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .popup-content {
    text-align: center;
    background: #fff;
    width: 400px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  
  .cross-icon {
    font-size: 25px;
    color: #cd381c;
    cursor: pointer;
    float: right;
  }
  
  .clinicCreateBtn {
    color: #00979e !important;
    cursor: pointer;
  }
  
  button#btn_clinicconsult_add {
    border-color: transparent;
  }
  
  .disableCreateBtn {
    color: #dddddd !important;
    pointer-events: none;
    cursor: auto;
  }
  
  .clinicTextAlign input {
    text-align: left !important;
    ;
  }
  
  .nav-link.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  
  /* .receiveConsultation {
  color: #34989f;
  }
  
  .checkbox-inline {
  color: #34989f;
  } */
  .checkbox-inline.heading {
    font-size: 20px;
    margin-bottom: 0px;
  }
  
  .my-3.subHeading {
    /* color: #34989f; */
    font-size: 18px;
  }
  
  .ms-5.text-color {
    /* color: #34989f; */
    font-size: 18px;
  }
  
  td{
  text-align:left;
  width: 100%;
}
  .page-heading-parter{
    color: #00979e;
  font-size: 28px;
  padding-top: 10px;
  font-weight: bold;
  }
  
  @media screen and (max-width:991px) {
    .mobileStyleDoctor {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 6rem;
    }
  
    .mypracticeMobile {
      margin-left: 20px;
      /* margin-bottom: 10px; */
      margin-top: 1rem;
      color: #000;
      font-weight: 550;
      font-size: 21px;
    }

    @media (max-width:576px) {
  .text-center.name-fix {
    font-size: 15px;
  }
  fieldset.curve-box {
  border: 2px solid #00979e;
  border-radius: 20px;
  /* padding: 5px; */
}

fieldset.left-right {

padding: 0px 70px 13px 70px;

}
  td {
    padding: 0px 5px !important;

  }  fieldset.left-right {

padding: 0px 70px 13px 70px;

}



}
  }
  </style>
  